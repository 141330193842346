<template>
  <div class="container-dashboard">
    <div class="divBanniere">
      <span>Qey Qey !</span>
    </div>
    <div class="corpTableaudebord">
      <h1>Qey Qey, {{ userName }}!</h1>
      <div style="text-align: left; color: #2f4992; margin-left: 100px; margin-right: 100px;">
        <p>La Première Nation Wolastoqiyik Wahsipekuk (PNWW) est en pleine expansion, et plusieurs projets d’infrastructure majeurs sont en cours, notamment le Carrefour commercial Wolastoqey sur la réserve de Kataskomiq, la reconstruction des bureaux administratifs et la rénovation du resto-pub de Cacouna. La PNWW doit investir une mise de fonds représentant au minimum 10 % du coût de chaque projet, et ces montants ne peuvent être retirés du fonds de roulement de la PNWW sans impliquer les opérations courantes.</p>
        <br />
        <p>En 2019, la PNWW a réservé un montant de 2 500 000 $ pour, entre autres, le projet de développement de la réserve de Withworth (maintenant nommée Kataskomiq) par la résolution 2019-08-07-02. Le temps est désormais venu d’utiliser les sommes mises en réserve pour la réalisation de ces projets, qui sont majeurs pour l’avenir de la PNWW.</p>
        <br />
        <p>Conformément avec l’article 10.9 de la Politique financière (2023) de la PNWW, le Grand Conseil a adopté une résolution qu’il a partagée avec le Comité des finances et d’audit ainsi qu’avec le Conseil des Sages. Cette résolution est maintenant publiée sur notre site Web pour une période de 30 jours, afin d’informer les Wolastoqiyik de l’intention de retirer ces fonds d’un placement, pour la réalisation des projets actuellement en cours.</p>
        <p><a href="/doc_retrait.pdf">Voir le document</a></p>
        <br>
      </div>
      <div v-for="lien in listeDeLiens" :key="lien.num">
        <h1 class="titleLink" v-if="lien.text.length > 0">{{ lien.text }}</h1>
        <div class="moduleDash" v-if="lien.items.length > 0">
          <span v-for="item in lien.items" :key="item.num">
            <div v-if="item.type === 'info'" class="info">
              {{ $t(item.text) }}
            </div>
            <div v-else class="moduleContainer">
              <router-link
                v-if="item.type != 'title'"
                class="module"
                :id="item.id"
                :to="item.to"
              >
                <img
                  style="max-height: 60px"
                  :src="item.img.src"
                  :alt="item.img.alt"
                />
                <h2>{{ item.text }}</h2>
              </router-link>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AccountType from '@/types/enums/AccountType';
import { defineComponent } from 'vue';
import commonMessages from '../locales/common';
export default defineComponent({
  name: 'DashBoard',
  i18n: {
    sharedMessages: commonMessages,
  },
  data() {
    return {
      userName: '',
    };
  },
  computed: {
    listeDeLiens() {
      let listeDeLiensToReturn;
      const lienAdmin = [
        {
          num: 1,
          type: 'title',
          text: 'DISTRIBUTION COMMUNAUTAIRE',
          items: [
            {
              num: 3,
              to: { name: 'ListeCouponsAdmin' },
              img: {
                src: require('../assets/img/icone-coupons-manuels.svg'),
                alt: 'icone coupons manuels',
              },
              text: 'Coupons créés manuellement',
              id: 'btn-coupons',
            },
            {
              num: 4,
              to: { path: '/community-distribution-report' },
              img: {
                src: require('../assets/img/icone-commandes.svg'),
                alt: 'icone commandes',
              },
              text: 'Commandes',
            },
            {
              num: 5,
              to: { path: '/distribution-site-report' },
              img: {
                src: require('../assets/img/icone-rapport-sd.svg'),
                alt: 'icone rapport sd',
              },
              text: 'Rapport - Sites de distribution',
            },
            {
              num: 6,
              to: { name: 'RapportEcAdmin' },
              img: {
                src: require('../assets/img/icone-rapport-ec.svg'),
                alt: 'icone rapport état commandes',
              },
              text: 'Rapport - État des commandes',
            },
            {
              num: 14,
              to: { name: 'RapportCs' },
              img: {
                src: require('../assets/img/icone-rapport-ec.svg'),
                alt: 'icone rapport commandes supprimées',
              },
              text: 'Rapport - Commandes supprimées',
            },
          ],
        },
        {
          num: 7,
          type: 'title',
          text: 'CHASSE ET PÊCHE',
          items: [
            {
              num: 8,
              to: { name: 'RapportCommandesPermisAdmin' },
              img: {
                src: require('../assets/img/icone-rapport-cp.svg'),
                alt: 'icone rapport commandes permis',
              },
              text: 'Rapport - Commandes de permis',
            },
          ],
        },
        {
          num: 9,
          type: 'title',
          text: "PARAMÈTRES DE L'APPLICATION",
          items: [
            {
              num: 10,
              to: { name: 'ListeUtilisateurs' },
              img: {
                src: require('../assets/img/icone-liste-utilisateurs.svg'),
                alt: 'icone liste utilisateurs',
              },
              text: 'Liste des utilisateurs',
              tag: 'listeUtilisateurs',
            },
            {
              to: { name: 'assembleeGenerale' },
              img: {
                src: require('../assets/img/icon-espace-video.svg'),
                alt: 'icone assemblee generale',
              },
              text: this.$t('visionnerAssembleeGenerale'),
            },
            {
              to: { name: 'elections' },
              img: {
                src: require('../assets/img/icon-espace-video.svg'),
                alt: 'icone profil',
              },
              text: this.$t('elections'),
            },
            {
              to: { name: 'reponsesCandidatsElections' },
              img: {
                src: require('../assets/img/icon-espace-video.svg'),
                alt: 'icone profil',
              },
              text: this.$t('reponsesCandidatsElections'),
            },
            {
              to: { name: 'espaceVideo' },
              img: {
                src: require('../assets/img/icon-espace-video.svg'),
                alt: 'icone profil',
              },
              text: this.$t('espaceVideo'),
            },
            {
              to: { name: 'importationCouponsPromo' },
              img: {
                src: require('../assets/img/icone-commandes.svg'),
                alt: 'icone importation coupons',
              },
              text: this.$t('importationCouponsPromo'),
            },
          ],
        },
        {
          num: 11,
          type: 'title',
          text: "INSCRIPTION À L'ASSEMBLÉE GÉNÉRALE",
          items: [
            {
              num: 12,
              to: { name: 'ListeInscriptionsAssemblee' },
              img: {
                src: require('../assets/img/icone-inscriptions.svg'),
                alt: 'liste inscriptions assemblee',
              },
              text: 'Liste des inscriptions',
            },
            {
              num: 13,
              to: { name: 'ListeInscriptionsAssembleeAdmin' },
              img: {
                src: require('../assets/img/icone-inscriptions-manuelles.svg'),
                alt: 'liste inscriptions assemblee admin',
              },
              text: 'Liste des inscriptions manuelles',
            },
          ],
        },
      ];
      const lienDistributor = [
        {
          num: 1,
          type: 'title',
          text: '',
          items: [
            {
              to: { name: 'OrderScan' },
              img: {
                src: require('../assets/img/icon-admin.png'),
                alt: 'icone scanner commandes',
              },
              text: 'Scanner une commande',
            },
            {
              to: { name: 'RapportEc' },
              img: {
                src: require('../assets/img/icon-admin.png'),
                alt: 'icone rapport état commandes',
              },
              text: 'Rapport état des commandes',
            },
          ],
        },
      ];
      const lienUser = [
        {
          num: 1,
          type: 'title',
          text: '',
          items: [
            {
              to: { name: 'assembleeGenerale' },
              img: {
                src: require('../assets/img/icon-espace-video.svg'),
                alt: 'icone assemblee generale',
              },
              text: this.$t('visionnerAssembleeGenerale'),
            },
            {
              to: { name: 'couponPromoMembre' },
              img: {
                src: require('../assets/img/icone-coupons.svg'),
                alt: 'icone coupons',
              },
              text: this.$t('monCouponPromo'),
            },
            {
              to: { name: 'reponsesCandidatsElections' },
              img: {
                src: require('../assets/img/icon-espace-video.svg'),
                alt: 'icone profil',
              },
              text: this.$t('reponsesCandidatsElections'),
            },
            {
              to: { name: 'elections' },
              img: {
                src: require('../assets/img/icon-espace-video.svg'),
                alt: 'icone profil',
              },
              text: this.$t('elections'),
            },
            {
              to: { name: 'espaceVideo' },
              img: {
                src: require('../assets/img/icon-espace-video.svg'),
                alt: 'icone profil',
              },
              text: this.$t('espaceVideo'),
            },
            {
              to: { name: 'coupons' },
              img: {
                src: require('../assets/img/icone-coupons.svg'),
                alt: 'icone coupons',
              },
              text: this.$t('mesCoupons'),
              id: 'btn-coupons',
            },
            {
              to: { name: 'permis' },
              img: {
                src: require('../assets/img/icone-permis.svg'),
                alt: 'icone permis',
              },
              text: this.$t('mesPermis'),
              id: 'btn-permis',
            },
            {
              to: { name: 'profil' },
              img: {
                src: require('../assets/img/icone-profil.png'),
                alt: 'icone profil',
              },
              text: this.$t('updateProfile'),
            },
          ],
        },
      ];
      const accountType = this.$store.getters['Auth/accountType'];
      switch (accountType) {
        case AccountType.User:
          listeDeLiensToReturn = lienUser;
          break;
        case AccountType.Admin:
          listeDeLiensToReturn = lienAdmin;
          break;
        case AccountType.Distributor:
          listeDeLiensToReturn = lienDistributor;
          break;
      }
      return listeDeLiensToReturn;
    },
  },
  created() {
    this.userName = JSON.parse(sessionStorage.user).firstName;
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/scss/_layout.scss';
.container-dashboard {
  padding-bottom: 200px;
}
.divBanniere {
  background-image: url('@/assets/img/banChute.png');
  background-color: $colorBleuFonce;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  display: flex;
  align-items: flex-end;
  padding: 0px;
  height: 25vh;
  margin: 0px;

  span {
    color: #d2d8ee;
    font-size: 11rem;
    margin-bottom: -13px;
    padding-left: 8%;
    font-weight: 100;
    line-height: normal;
  }
}
.corpTableaudebord {
  text-align: center;

  h1 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.moduleDash {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 60%;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  @include media-breakpoint-down($xl) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 2rem;
  }
}
.moduleContainer {
  width: 29vw;
}
.titleLink {
  border-bottom: 1px solid $grisFonce;
  color: $grisFonce;
  text-transform: uppercase;
  margin-bottom: 40px;
  margin-top: 40px;
}
.info {
  width: 60vw;
  text-align: center;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  background-color: #fce7d7;
  border: 1px solid #f48531;
}
.module {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  background-color: white;
  border-radius: 20%;
  margin: 10px;
  border: 2px solid $colorBleuFonce;
  border-radius: 16px;
  box-shadow: 0px 0px 6px #87a8ea21;
  color: $colorBleuFonce;
  min-height: 15vh;
  height: auto;
  transition: border-color 0.8s ease;
  &:hover {
    border: 4px solid red;
    cursor: pointer;
  }

  h2 {
    margin-top: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .moduleDash {
    flex-direction: column;
    width: 100%;
    margin-bottom: 2rem;
  }
  .divBanniere {
    height: auto;
    padding-top: 1vh;
    padding-bottom: 1vh;

    span {
      font-size: 4rem;
    }
  }
  .moduleContainer {
    width: 80vw;
  }
}
.module h2 {
  margin-top: 20px;
}
</style>
